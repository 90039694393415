<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-row :gutter="24">
<!--        <a-col :span="4">-->
<!--          &lt;!&ndash; 部门树 &ndash;&gt;-->
<!--          <dept-tree ref="deptTree" :deptOptions="deptOptions" @select="clickDeptNode" />-->
<!--        </a-col>-->
        <a-col :span="20">
          <!-- 条件搜索 -->
<!--          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :md="8" :sm="24">
                  <a-form-item label="用户名称">
                    <a-input v-model="queryParam.userName" :placeholder="$t('通用.输入.请输入')" allow-clear />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="手机号">
                    <a-input v-model="queryParam.phoneNumber" :placeholder="$t('通用.输入.请输入')" allow-clear />
                  </a-form-item>
                </a-col>
                <template v-if="advanced">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="状态" prop="status">
                      <a-select v-model="queryParam.status" :placeholder="$t('通用.输入.请选择')" style="width: 100%" allow-clear>
                        <a-select-option v-for="(d, index) in dict.type['sys_normal_disable']" :key="index"
                          :value="d.value">{{ d.label }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item :label="$t('系统用户.开始时间')" prop="startTime">
                      <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime" valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item :label="$t('系统用户.结束时间')" prop="endTime">
                      <a-date-picker style="width: 100%" v-model="queryParam.createEndTime" valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                    </a-form-item>
                  </a-col>
                </template>
                <a-col :md="!advanced && 8 || 24" :sm="24">
                  <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                    <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                    <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
                    <a @click="toggleAdvanced" style="margin-left: 8px">
                      {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}
                      <a-icon :type="advanced ? 'up' : 'down'" />
                    </a>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>-->
          <!-- 操作 -->
<!--          <div class="table-operations">
            <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['system:user:add']">
              <a-icon type="plus" />{{$t('通用.按钮.新增')}}
            </a-button>
            <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)"
              v-hasPermi="['system:user:edit']">
               <a-icon type="edit" />{{$t('通用.按钮.修改')}}
            </a-button>
            <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['system:user:remove']">
              <a-icon type="delete" />{{$t('通用.按钮.删除')}}
            </a-button>
&lt;!&ndash;            <a-button type="dashed" @click="$refs.importExcel.importExcelHandleOpen()"
              v-hasPermi="['system:user:import']">
              <a-icon type="import" />{{$t('通用.按钮.导入')}}
            </a-button>&ndash;&gt;
            <a-button type="primary" @click="handleExport" v-hasPermi="['system:user:export']">
              <a-icon type="download" />{{$t('通用.按钮.导出')}}
            </a-button>
            <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns"
              :refresh-loading="loading" @refresh="getList" />
          </div>-->
          <!-- 增加修改 -->
          <!-- 创建/编辑用户,单独封装了组件 -->
          <create-form ref="createForm" :deptOptions="deptOptions" :statusOptions="dict.type['sys_normal_disable']"
            :sexOptions="dict.type['sys_user_sex']" @ok="getList" @select-tree="getTreeselect" />
          <!-- 修改密码抽屉 -->
          <reset-password ref="resetPassword" />
          <!-- 分配角色模态框 -->
          <auth-role ref="authRole" />
          <!-- 上传文件 -->
          <import-excel ref="importExcel" @ok="getList" />
          <!-- 数据展示 -->
          <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
            :bordered="tableBordered">
            <!-- tips: 此处的status 其实是后端的enable字段，所以status '0' 代表的是开启  status '1' 代表的是关闭 -->
            <span slot="status" slot-scope="text, record">
              <a-popconfirm ok-text="是" cancel-text="否" @confirm="confirmHandleStatus(record)"
                @cancel="cancelHandleStatus(record)">
                <span slot="title">确认<b>{{ record.status ===  0 ? '启用' : '停用' }}</b>{{ record.nickName }}的用户吗?</span>
                <a-switch checked-children="开" un-checked-children="关" :checked="record.status === 1 " />
              </a-popconfirm>
            </span>
            <span slot="createTime" slot-scope="text, record">
              {{ parseTime(record.createTime) }}
            </span>
            <span slot="operation" slot-scope="text, record" v-if="record.id !== 1">
<!--              <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['system:user:edit']">
                <a-icon type="edit" />
                修改
              </a>
              <a-divider type="vertical" v-hasPermi="['system:user:remove']" />
              <a @click="handleDelete(record)" v-hasPermi="['system:user:remove']">
                <a-icon type="delete" />
                删除
              </a>-->
              <a-divider type="vertical" v-hasPermi="['system:user:resetPwd', 'system:user:edit']" />
              <a-dropdown v-hasPermi="['system:user:resetPwd', 'system:user:edit']">
                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                  <a-icon type="double-right" />
                  更多
                </a>
                <a-menu slot="overlay">
                  <a-menu-item v-hasPermi="['system:user:resetPwd']">
                    <a @click="$refs.resetPassword.handleResetPwd(record)">
                      <a-icon type="key" />
                      重置密码
                    </a>
                  </a-menu-item>
                  <a-menu-item v-hasPermi="['system:user:edit']">
                    <a @click="$refs.authRole.handleAuthRole(record)">
                      <a-icon type="check-circle" />
                      分配角色
                    </a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </span>
          </a-table>
          <!-- 分页 -->
          <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
            :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
            @showSizeChange="onShowSizeChange" @change="changeSize" />
        </a-col>
      </a-row>
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { pageUser, delUser, changeUserStatus, deptTreeSelect } from '@/api/system/user'
import AuthRole from './modules/AuthRole'
import ResetPassword from './modules/ResetPassword'
import CreateForm from './modules/CreateForm'
import ImportExcel from './modules/ImportExcel'
import DeptTree from './modules/DeptTree'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'User',
  components: {
    AuthRole,
    ResetPassword,
    CreateForm,
    ImportExcel,
    DeptTree
  },
  mixins: [tableMixin],
  dicts: ['sys_normal_disable', 'sys_user_sex'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 部门树选项
      deptOptions: [{
        id: 0,
        label: '',
        children: []
      }],
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        createBeginTime:undefined,
        createEndTime:undefined,
        userName: undefined,
        phoneNumber: undefined,
        status: undefined,
        deptId: undefined
      },
      columns: [
        {
          title: '用户编号',
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: '用户名',
          dataIndex: 'userName',
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'phoneNumber',
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickName',
          align: 'center'
        },
        // {
        //   title: '部门',
        //   dataIndex: 'dept.deptName',
        //   scopedSlots: { customRender: 'dept.deptName' },
        //   align: 'center'
        // },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
    this.getDeptTree()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getTreeselect() { },
    /** 查询用户列表 */
    getList() {
      this.loading = true
      pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      }
      )
    },
    /** 查询部门下拉树结构 */
    getDeptTree() {
      deptTreeSelect().then(response => {
        this.deptOptions = response.data
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        createBeginTime:undefined,
        createEndTime:undefined,
        userName: undefined,
        phoneNumber: undefined,
        status: undefined,
        deptId: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    clickDeptNode(deptId) {
      this.queryParam.deptId = deptId
      this.handleQuery()
    },
    /* 用户状态修改 status 0开启 1关闭*/
    confirmHandleStatus(row) {
      const oldStatus = row.status
      const text = row.status === 0 ? '启用' : '关闭'
      const status = row.status === 1 ? 0 : 1
      changeUserStatus(row.id, status).then((res) => {
        if (res.success) {
          row.status = status
          this.$message.success(text + '成功', 3)
        }else{
          row.status = oldStatus
          this.$message.error(`${text}失败, ${res.message}`, 3)
        }
      }).catch(() => {
        this.$message.error(text + '异常', 3)
      })
    },
    cancelHandleStatus(row) {
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const userIds = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + userIds + '的数据',
        onOk() {
          return delUser(userIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() { }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('system/user/export', {
            ...that.queryParam
          }, `user_${new Date().getTime()}.xlsx`)
        },
        onCancel() { }
      })
    }
  }
}
</script>
